.gd-1-2 {
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.gd-1-1 {
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.block-paint-damage {
  grid-area: 1 / 1 / 4 / 2;
  height: 572px;
  width: 563px;
  min-height: 572px;
  min-width: 563px;
  border-radius: 20px;
  padding: 0px;
  overflow: hidden;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: 1px solid #e2e1e1;
}

.block-damage-list {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.block-jcsb {
  display: flex;
  justify-content: space-between;
}

.damage-paint-delete {
  background-color: #f44336;
  color: #ffffff;
  padding: 2px;
  border-radius: 10px;
  font-size: 15px !important;
  margin-right: 0px;
}

.text-area-damage {
  width: 300px;
  height: 20px;
  border: none;
  border-radius: 5px;
  padding-left: 8px;
}

.point-text {
  margin: 0px;
}

.point-text-mini {
  margin-top: 7px !important;
  font-size: 13px;
  margin: 0px;
}

.d-flex {
  display: flex;
  width: fit-content;
}

.point-text-radius {
  margin: 0;
  background-color: black;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  /* Выравнивание по горизонтали */
  align-items: center;
  /* Выравнивание по вертикали */
  font-size: 14px;
  margin-right: 10px;
}

.box-block-edit-params {
  background-color: white;
  margin-top: 30px;
  padding: 20px;
  border-radius: 20px;
}

.box-damages-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
  margin-top: 30px;
  padding: 20px;
  border-radius: 20px;
}

.icon-box-view {
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  margin-right: 20px;
}

.box-black-info {
  background-color: #17181a;
  border-radius: 20px;
  padding: 30px;
  background-color: #17181a;
  width: 350px;
  height: 110px;
}

.box-main-info-car {
  background-color: white;
  height: fit-content !important;
  width: 500px;
  border-radius: 20px;
  padding: 20px;
  overflow: hidden;
  background-color: #17181a;
  color: white;
}

.box-main-info {
  background-color: white;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  border-radius: 20px;
  padding: 20px;
}

.photo_for_card_view {
  height: auto !important;
  width: 100% !important;
  position: inherit !important;
  margin: 0 !important;
}

.img-block {
  height: 330px;
  overflow: hidden;
  border-radius: 10px;
}

.flex-two-info {
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}

.h1-m {
  font-size: 25px;
  font-weight: 600;
  margin-top: 10px;
}

.p-mini {
  font-size: 13px;
  font-weight: 400;
  margin: 0px;
  color: #ffffffad;
}

.p-gos {
  border: 1px solid #ffffff;
  padding: 5px;
  border-radius: 6px;
  width: fit-content;
  margin-top: 10px;
}

.tasks-flex {
  display: flex;
}

.tasks-badge {
  height: 10px !important;
  width: 10px !important;
  min-width: 0px !important;
  padding: 0px;
  margin: 0px;
  margin-top: 14px;
  border-radius: 50px;
  background-color: #f44336;
}

/* Поля инпута */

.grid-two-info {
  display: grid;
  grid-template-columns: 200px 1fr;
  justify-content: space-between;
  align-items: center;
}

.top-t-input {
  margin-bottom: 1px;
  padding: 0px;
  font-size: 13px;
  color: gray;
}

.input-read-auto {
  font-size: 14px;
  font-weight: 400;
  border-radius: 7px;
  margin: 0px;
}

.top-t {
  font-size: 14px;
  margin-bottom: 10px;
}

.top-t2 {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.box-info-grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto 1fr;
  column-gap: 5px;
  grid-gap: 20px;
  margin-bottom: 30px;
}

.box-info {
  height: fit-content;
  width: -webkit-fill-available;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  border-radius: 20px;
  padding-right: 15px;
  border: none;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.box-info:hover {
  transition: 0.3s;
  transform: scale(1.07) !important;
}

.box-info:active {
  transition: 0.2s;
  transform: scale(1) !important;
}

.box-info.active {
  transition: 0.2s;
  transform: scale(1) !important;
  background-color: #ebf2fc;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
}

.box-info-icon {
  text-align: center;
  background-color: #7bbaff;
  border-radius: 15px;
  padding: 10px;
  margin-right: 20px;
  font-size: 35px !important;
  color: white;
}

.repair-icon {
  background-color: #ffbaba;
}

.free-auto {
  background-color: #cce4ff;
}

.crash-auto {
  background-color: #ff8482;
}

.p-info {
  margin-block-start: 0;
  margin-block-end: 0em;
  font-size: 14px;
}

.p-b-info {
  margin-block-start: 0em;
  margin-block-end: 0px;
  font-size: 34px;
  font-weight: 600;
  text-align: justify;
}

.p-big-update {
  font-size: 14px;
  font-weight: 800;
  margin-block-start: 3em;
  margin-block-end: 1em;
}

.p-start {
  margin-block-start: 0em !important;
}

.text-for-input-update-auto {
  font-size: 14px;
  margin-block-start: 10%;
  margin-block-end: 15%;
  font-weight: 300;
}

.text-for-input-update-auto-2-2 {
  font-size: 13px;
  margin-block-start: 5%;
  margin-block-end: 4%;
  font-weight: 300;
}

.select-update {
  width: -webkit-fill-available !important;
}

.p-gray {
  color: rgb(175, 175, 175);
}

.text-view-auto {
  margin-bottom: 1px;
  padding: 0px;
  font-size: 13px;
  color: rgb(182, 182, 182);
  margin-top: 0px;
}

.text-view-input-auto {
  margin-bottom: 1px;
  padding: 0px;
  font-size: 13px;
  color: rgb(0, 0, 0);
  margin-top: 0px;
}

.text-view-auto-white {
  margin-bottom: 1px;
  padding: 0px;
  font-size: 13px;
  color: rgb(0, 0, 0);
  margin-top: 0px;
}

.icon-view-car {
  padding: 8px;
}

.styles-select {
  height: 32px !important;
  border-radius: 6px !important;
}

.alert-end {
  padding: 3px;
  border-radius: 5px;
  color: white;
  background-color: red;
}

.box-files-grid {
  display: flex;
}

.box-files {
  width: 100px;
  height: 130px;
  border: 1px solid #a5a5a5;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: justify;
  overflow: hidden;
}

.grid-docs {
  display: flex;
  gap: 15px;
}

.error-files {
  background-color: #dfdfdf78;
  border: 1px solid #a4a4a4;
}

.icon-files {
  background-color: white;
  color: #000000;
  padding: 7px;
  border-radius: 10px;
  font-size: 25px !important;
  margin-right: 10px;
}

.icon-mini {
  color: #2196f3;
  padding: 10px;
  border-radius: 50px;
  font-size: 15px !important;
  margin-right: 10px;
  margin-top: 10px;
  background-color: #a7a7a7;
  color: white;
}

.icon-t {
  font-size: 14px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  color: black;
}

.succes-files {
  border: 1px solid #b0d3be;
  background-color: #d1efcd73;
}

.chip-success-upload {
  background-color: #8bc34a;
  border-radius: 10px;
  padding: 1px 7px 1px 7px;
  font-size: 10px;
  margin-block-start: 0px;
  text-align: center;
  width: fit-content;
  color: black;
}

.error-chip {
  background-color: #ff7f7f;
}

.error-icon {
  background-color: #c0c0c0;
  color: white;
}

.mr-15 {
  margin-right: 15px;
}

.full-width {
  width: 150px;
}

.d-flex {
  display: flex;
}

.icon-downloads-docs {
  background-color: #436cff;
  color: white;
  padding: 5px;
  font-size: 18px !important;
  border-radius: 50px;
  box-shadow: 1px 2px 8px 3px rgb(56 112 255 / 41%);
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#file-upload {
  display: none;
}

.block-modal-gos {
  display: flex;
  flex-wrap: wrap;
}

.t-mini-modal-gos {
  border-radius: 5px;
  background-color: #eaeaea;
  padding: 3px;
  font-size: 13px;
  margin-right: 5px;
  overflow: hidden;
  margin-bottom: 0px;
  white-space: nowrap;
  font-weight: 500;
}

.t-h-modal-gos {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 0px;
  margin-top: 0px;
}

.mt-15 {
  margin-top: 30px;
}

/* для осмотра, клики по фото*/

.photo_for_card_view {
  position: relative;
  cursor: pointer;
}

.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  pointer-events: none;
  /* Игнорировать события мыши */
}

.mb-10 {
  margin-bottom: 20px;
}

.view-osmotr {
  padding: 5px;
  background-color: rgb(216, 216, 216);
  border-radius: 10px;
}

/* Кнопки загрузки файлов */ 

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Arial, sans-serif;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

.btn {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.choose-btn {
      /* background-color: #4CAF50; */
      color: #3d5aff;
      /* text-align: center; */
      /* display: inline-block; */
      font-size: 12px;
      padding: 0px;
}

 .height-20 {
  height: 20px;
 }

.replace-btn {
  background-color: #008CBA;
  color: white;
}

.choose-btn:hover {
  background-color: #45a049;
}

.replace-btn:hover {
  background-color: #007bb5;
}

.file-input {
  display: none;
}

.file-name {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
  word-break: break-all;
}