.storage-layout {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    height: calc(100vh - 200px);
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    -webkit-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    -moz-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    overflow-x: auto;
}

.folder-column {
    min-width: 250px;
    max-width: 250px;
    border-right: 1px solid #ececec;
    padding-right: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.folder-column:last-child {
    border-right: none;
}

.folder-column-header {
    padding: 10px 0;
    border-bottom: 1px solid #ececec;
    margin-bottom: 10px;
}

.folder-column-title {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #436cff;
}

.folder-list {
    overflow-y: auto;
    flex-grow: 1;
}

.folder-tree-item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 2px;
    transition: transform 0.2s;
}

.folder-tree-item:hover {
    background-color: #ebf2fc;
    transform: scale(1.02);
}

.folder-tree-item.selected {
    background-color: #ebf2fc;
    border: 1px solid #9385ff;
}

.folder-tree-item .folder-icon {
    color: #436cff;
    margin-right: 8px;
}

.folder-tree-item .chevron-icon {
    color: #436cff;
    margin-left: auto;
    margin-right: 8px;
}

.folder-tree-item .folder-name {
    flex-grow: 1;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.folder-tree-item .delete-icon {
    visibility: hidden;
    color: #ff3232c7;
    padding: 5px;
    border-radius: 50px;
}

.folder-tree-item:hover .delete-icon {
    visibility: visible;
}

.folder-tree-item .delete-icon:hover {
    color: rgb(255, 255, 255);
    background-color: #f44336;
}

.files-panel {
    flex-grow: 1;
    overflow-y: auto;
    min-width: 300px;
    padding-left: 20px;
}

.files-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.file-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: rgb(255 255 255);
    border-radius: 20px;
    -webkit-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    -moz-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    transition: transform 0.2s;
}

.file-item:hover {
    transform: scale(1.07);
}

.file-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    flex-grow: 1;
}

.file-icon {
    color: #436cff;
    margin-right: 8px;
}

.file-name {
    font-size: 13px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.storage-toolbar {
    margin: 20px 0;
    display: flex;
    gap: 10px;
}

.toolbar-btn {
    padding: 7px 15px 7px 15px !important;
    color: white !important;
    background-color: #436cff !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    height: fit-content !important;
}

.storage-breadcrumb {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 10px;
}

.breadcrumb-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #436cff;
    cursor: pointer;
}

.breadcrumb-item:hover {
    text-decoration: underline;
}

.breadcrumb-separator {
    margin: 0 8px;
    color: #ececec;
}

/* Стили для пустого состояния */
.empty-state {
    text-align: center;
    padding: 2rem;
    color: #686868;
    font-size: 13px;
}

/* Стили для модального окна */
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    padding: 20px;
}

.modal-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.modal-input {
    width: 100%;
    margin-bottom: 20px !important;
}

.modal-input input {
    border-radius: 10px !important;
    font-size: 14px !important;
}

.modal-button {
    padding: 7px 15px 7px 15px !important;
    color: white !important;
    background-color: #436cff !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    height: fit-content !important;
}