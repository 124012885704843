.login-page-body {
    display: grid;
    height: -moz-fit-content;
    height: fit-content;
    grid-template-columns: 40% 60%;

    height: 100vh;
}

.login-page-block-first {
    height: 100vh;
    background-color: white;
    overflow: hidden;
    vertical-align: middle;
    position: relative;
}

.login-page-block-two {
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 20px;
}

.login-btn-block {
    margin-bottom: 20px;
}

.login-page-block-f {
    height: fit-content;
    width: -webkit-fill-available;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding-left: 20%;
    padding-right: 20%;
}

.img_singIn {
    height: 100vh;
}

.img_logo {
    height: 40px;
    margin-top: 20px;
    margin-left: 30px;
}

.mt-podtext {
    margin-bottom: 30px;
    font-size: 12px;
    color: #686868;
}

.btn-enter-sign {
    background-color: black !important;
    ;
}

hr {
    margin-top: 20px;
}

.error-signin {
    background-color: #ffe4e4;
    border: 1px solid #ffb2b2;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px;
}

@media (min-width: 20px) and (max-width: 424px) {
    .login-page-body {
        display: grid;
        height: -moz-fit-content;
        height: fit-content;
        grid-template-columns: 1fr;
        height: 100vh;
    }

    .login-page-block-f {
        height: fit-content;
        width: -webkit-fill-available;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        padding-left: 10%;
        padding-right: 10%;
    }

    .header-mt {
        font-size: 25px;
        font-weight: 900;
        text-transform: uppercase;
    }
}