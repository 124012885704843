.gd-1-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr) repeat(0, 0);
    grid-template-rows: repeat(0, 1fr) repeat(2, 0);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.div1 {
    grid-area: 1 / 1 / 4 / 2;
    height: fit-content;
    width: -webkit-fill-available;
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: 1px solid #e2e1e1;
}

.div2 {
    grid-area: 1 / 2 / 2 / 3;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    height: fit-content;
    border: 1px solid #e2e1e1;
}

.width-div2-info {
    width: -webkit-fill-available !important;
}

.div3 {
    grid-area: 2 / 2 / 3 / 3;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    border: 1px solid #e2e1e1;
}



.box-main-info-car {
    background-color: white;
    height: 370px;
    width: 330px;
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    background-color: #17181a;
    color: white;
}

.box-main-info {
    background-color: white;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    border-radius: 20px;
    padding: 20px;
}

.photo_for_card_view {
    height: 200px;
    width: auto;
    position: inherit;
    margin-left: 30px;
    margin-top: 20px;
}

.flex-two-info {
    display: flex;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
}

.h1-m {
    font-size: 25px;
    font-weight: 600;
    margin-top: 10px;
}

.p-mini {
    font-size: 13px;
    font-weight: 400;
    margin: 0px;
    color: #000000ad;
}

.p-gos {
    border: 1px solid #ffffff;
    padding: 5px;
    border-radius: 6px;
    width: fit-content;
    margin-top: 10px;
}

/* Поля инпута */

.grid-two-info {
    display: grid;
    grid-template-columns: 200px 1fr;
    justify-content: space-between;
    align-items: center;
}

.top-t-input {
    margin-bottom: 1px;
    padding: 0px;
    font-size: 13px;
    color: gray;
}

.input-read-auto {
    font-size: 14px;
    font-weight: 400;
    border-radius: 7px;
    margin: 0px;
}

.text-before-input {
    font-size: 16px;
    margin-bottom: 10px;
    margin-block-start: 7px;
    color: white;
    font-weight: 600;
}

.top-t {
    font-size: 14px;
    margin-bottom: 10px;
}

.top-t2 {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 30px;
}

.header-modal-TechInsp {
    font-size: 20px;
    font-weight: 200;
    text-align: center;
    margin-bottom: 30px;
}

.textArea_bodyText_tech {
    height: 100px !important;
    border: 1px solid #c4c4c4;
    border-radius: 10px !important;
    font-size: 14px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    width: -webkit-fill-available !important;
}

.select-add-techinsp {
    height: 40px !important;
    width: -webkit-fill-available;
}

.grid-form-add {
    margin-top: 20px;
    margin-bottom: 7px;
}

.block-grid-damages-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.label_form-add {
    font-size: 14px !important;
}

.input-update-auto {
    padding: 7px;
    border: 0px solid #c4c4c4;
    background-color: #f0f0f0;
    border-radius: 6px;
    width: -webkit-fill-available;
    /* font-family: 'Rubik'; */
    font-family: "Roboto", sans-serif;
    font-size: 15px;
}

.text-input-update-auto {
    font-size: 14px;
    margin-block-start: 10%;
    margin-block-end: 15%;
    font-weight: 300;
}

.t-input-vw-repair {
    font-size: 14px;
    margin-block-start: 5px;
    margin-block-end: 15px;
    font-weight: 300;
    width: 160px;
    min-width: 70px;
}

.text-input-update-auto-2-2 {
    font-size: 14px;
    margin-block-start: 4%;
    margin-block-end: 6%;
    font-weight: 300;
}

.gos-b {
    font-weight: 600;
    border: 1px solid #bdbdbd;
    padding: 4px;
    margin: 0px;
    text-align: center;
    width: -webkit-fill-available;
    border-radius: 6px;
}

.width-th-tr {
    width: 65px;
}

.check-repair {
    margin: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 17px;
    height: 17px;
}

.grid-repair-view {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    column-gap: 0px;
}

.gd-flex {
    display: grid;
    grid-template-columns: 40% 60% !important;
}

.inp-date-time {
    height: 17px;
    margin-right: 20px;
    font-size: 14px;

}

.grid-1-block-repair-view {
    width: -webkit-fill-available !important;
    background-color: white;
    border-radius: 20px;
    margin-right: 20px;
    padding: 20px;
}

.grid-2-block-repair-view {
    background-color: white;
    border-radius: 20px;
}

.textArea-height {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    border: 1px solid #c4c4c4;
    padding: 10px;
    height: 80px;
    border-radius: 10px;
    width: -webkit-fill-available !important;
}

.label-text-view {
    margin-block-start: 10px !important;
    margin-block-end: 5px !important;
    color: #9a9a9a !important;
}

.gray-text {
    color: #9a9a9a !important;
}

.t-gray-mini {
    font-size: 8px;
    color: red;
}

.f-s-12px {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.hr-view-auto {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0.5px solid #e9e9e9;
}

.f-view-title {
    font-size: 13px;
    font-weight: 600;
    margin-top: 30px;
}

.gos-calendar-table {
    text-align: center;
    padding: 4px;
}

.gos-select-techInspect {
    background-color: #ffe68b;
}