.grid-tasks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.main_text_button {
    display: flex;
    justify-content: space-between;
}

.modal-grid-add-icon {
    font-size: 25px !important;
    color: #000000 !important;
    background-color: #ffffff !important;
    padding: 10px !important;
    margin-bottom: 20px !important;
    border-radius: 10px !important;
    margin-top: 15px !important;
}

.btn-create-task {
    padding: 7px 15px 7px 15px !important;
    color: white !important;
    background-color: #436cff !important;
    text-transform: capitalize !important;
    width: 100px !important;
    text-align: center !important;
    border-radius: 10px !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.textArea_bodyText {
    height: 150px !important;
    border: 1px solid #b0d0ff;
    margin-bottom: 10px;
    border-radius: 5px !important;
    font-size: 16px !important;
    padding: 7px;
    width: -webkit-fill-available !important;
    font-family: 'Rubik';
}

.label-inp-task {
    font-size: 13px !important;
    margin-top: 10px;
}

.select-inp-task {
    width: -webkit-fill-available !important;
    height: 40px !important;
    border-radius: 10px;
    background-color: white;
}

.box-tasks {
    height: 170px;
    width: -webkit-fill-available;
    background-color: rgb(255 255 255);
    /* background: linear-gradient(135deg, rgb(255, 168, 168) 10%, rgb(252, 255, 0) 100%); */
    border-radius: 20px;
    padding: 15px;
    -webkit-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    -moz-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    position: relative;
    transition: transform 0.2s;
}

.box-tasks:hover {
    height: 170px;
    width: -webkit-fill-available;
    background-color: rgb(255 255 255);
    /* background: linear-gradient(135deg, rgb(255, 168, 168) 10%, rgb(252, 255, 0) 100%); */
    border-radius: 20px;
    padding: 15px;
    -webkit-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    -moz-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    position: relative;
    transform: scale(1.07);
    transition: transform 0.2s;
}

.box-tasks-success {
    height: 170px;
    width: -webkit-fill-available;
    background-color: rgb(239 246 255);
    /* background: linear-gradient(135deg, rgb(255, 168, 168) 10%, rgb(252, 255, 0) 100%); */
    border-radius: 20px;
    padding: 15px;
    -webkit-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    -moz-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    position: relative;
    transition: transform 0.2s;
}

.box-tasks-add {
    height: 100px;
    width: 100px;
    background-color: #ebf2fc;
    /* background: linear-gradient(135deg, rgb(255, 168, 168) 10%, rgb(252, 255, 0) 100%); */
    border-radius: 20px;
    padding: 15px;
    -webkit-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    -moz-box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    box-shadow: 1px 4px 17px 4px rgba(29, 112, 176, 0.2);
    border: 1px solid #9385ff;
    position: relative;
}

.main-header-tasks {
    padding-top: 10px;
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
}

.main-bodytext-tasks {
    margin-top: 10px !important;
    font-size: 13px;
    margin: 0px;
    -webkit-line-clamp: 3;
    /* Число отображаемых строк */
    display: -webkit-box;
    /* Включаем флексбоксы */
    -webkit-box-orient: vertical;
    /* Вертикальная ориентация */
    overflow: hidden;
    /* Обрезаем */
}

.mini-gray-text {
    margin-top: 10px !important;
    font-size: 12px;
    color: gray;
    margin: 0px;
}

.main-icon-tasks {
    padding: 5px 0px 5px 0px;
    font-size: 12px;
    display: flex;
    position: absolute;
    bottom: 0px;
    margin-right: 15px;
    width: -webkit-fill-available;
    justify-content: space-between;
    border-top: 1px solid #ececec;
}

.icon-tasks-block {
    color: rgb(169, 169, 169);
    background-color: #f2f2f2;
    padding: 5px;
    border-radius: 50px;
}

.icon-tasks-block-succes {
    color: rgb(169, 169, 169);
    padding: 5px;
    border-radius: 50px;
}

.icon-tasks-block-delete {
    color: rgb(169, 169, 169);
    padding: 5px;
    border-radius: 50px;
}

.icon-tasks-block:hover {
    color: rgb(255, 255, 255);
    background-color: #bebebe;
    padding: 5px;
    border-radius: 50px;
}

.icon-tasks-block-succes:hover {
    color: rgb(255, 255, 255);
    background-color: #90eb25;
    padding: 5px;
    border-radius: 50px;
}

.icon-tasks-block-delete:hover {
    color: rgb(255, 255, 255);
    background-color: #f44336;
    padding: 5px;
    border-radius: 50px;
}

.icon-background .btn-add-modal {
    text-align: center !important;
    font-size: 13px !important;
    margin: 0 !important;
    padding: 13px !important;
    background-color: #9acd31 !important;
    width: 100 !important;
    display: block !important;
    margin-top: 20px !important;
    border-radius: 10px !important;
    color: black !important;
    border: none !important;
    width: -webkit-fill-available !important;
}

.gos-task {
    background-color: #ebebeb;
    margin-right: 7px;
    width: fit-content;
    padding: 4px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.chip-action-task {
    font-weight: 500 !important;
    margin-right: 10px;
    width: fit-content;
    border-radius: 6px;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.chip-task-red {
    background-color: #ff3232c7;
    color: #ffffff;
    font-weight: 500 !important;
    margin-right: 10px;
    width: fit-content;
    padding: 4px;
    border-radius: 6px;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.chip-task-blue {
    background-color: #cfd6ffc7;
    color: #0800aa;
    font-weight: 500 !important;
    margin-right: 10px;
    width: fit-content;
    padding: 4px;
    border-radius: 6px;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.chip-task-gray {
    background-color: #c6c6c64f;
    color: #686868;
    font-weight: 500 !important;
    margin-right: 10px;
    width: fit-content;
    padding: 4px;
    border-radius: 6px;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.chip-task-yellow {
    background-color: #ffd00085;
    color: #7d7100;
    font-weight: 500 !important;
    margin-right: 10px;
    width: fit-content;
    padding: 4px;
    border-radius: 6px;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.icon-image-success {
    width: 100px;
}

.drawer {
    position: fixed;
    top: 0;
    right: -500px;
    height: 100%;
    width: 400px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: right 0.5s ease;
    z-index: 9999;
}

.drawer.open {
    right: 0;
}

.drawer-content {
    padding: 16px;
}

.drawer button {
    margin-right: 8px;
}

.drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    /* Темный фон с прозрачностью */
    backdrop-filter: blur(10px);
    /* Размыть фон (требуется поддержка браузером) */
    z-index: 9999;
}

.f-gos {
    margin-bottom: 30px;
}

.f-body {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

.container-chip {
    display: flex;
}