.logo-t {
  color: white;
  font-weight: 900;
  margin-left: 30%;
  margin-top: 20px;
  font-size: 25px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Дашбоард начала */

.grid-dashboard-menu {
  width: auto;
  border-radius: 10px;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.card-dashboard {
  background-color: white;
  height: 200px;
  border-radius: 20px;
  padding: 10px;
}

.card-dashboard-img-container {
  height: 120px;
  overflow: hidden;
  border-radius: 20px;
}

.card-dashboard-img {
  width: 100%;
}

/* Дашбоард конец */
/* */

table.blueTable {
  width: 100%;
  border-collapse: collapse;
}

table.blueTable td,
table.blueTable th {
  border: 1px solid #aaaaaa;
}

table.blueTable tbody td {
  font-size: 13px;
}

table.blueTable thead {
  background: #7f7f7f;
}

table.blueTable thead th {
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
}

table.blueTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}

table.blueTable tfoot td {
  font-size: 14px;
}

table.blueTable tfoot .links {
  text-align: right;
}

table.blueTable tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}

.grid {
  display: grid;
  height: fit-content;
  grid-template-areas:
    "nav header "
    "nav content ";
  grid-template-rows: 60px 1fr;
  grid-template-columns: 210px auto;
  background-color: #ffffff;
  height: 100vh;
}

/* Header start */

.header {
  grid-area: header;
  height: 70px;
  position: relative;
  background-color: white;
}

.header-date-time {
  font-weight: 900;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  padding: 7px;
  border-radius: 15px;
  font-size: 12px;
  margin-top: 22px;
  margin-left: 15px;
}

/* Header end */

.content {
  grid-area: content;
  border-radius: 25px;
  padding: 20px;
  background-color: #f3f3f3;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 12px;
}

.login-page-block {
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  background-color: #e1ebf1;
  height: 100vh;
}

.box-1 {
  background-color: #d3e0e8;
  width: 130px;
  height: 130px;
  border-radius: 20px;
  font-weight: 800;
  padding: 15px;
}

a {
  text-decoration: none;
}

/* Sidebar - start */

.sidebar {
  grid-area: nav;
  border-right: 1px solid #c6c6c6;
  color: black;
  height: 100vh;
  min-width: 190px;
  background-color: #171f22;
  position: fixed;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 30px;
}

.accaunt-sidebar {
  font-size: 14px;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-left: 30px;
  width: fit-content;
}

.sidebar-list-down {
  margin-bottom: 10px;
  display: block;
}

.sidebar-list-down:hover {
  background-color: #2196f3;
  border-radius: 5px;
  margin-right: 20px;
  color: black;
  width: -webkit-fill-available;
  display: block;
  margin-bottom: 10px;
  padding: 7px;
}

.sidebar-list {
  margin-top: 50px;
  margin-left: 20px;
}

.logo-header {
  height: 70px;
  overflow: hidden;
}

.logo-div {
  width: 150px;
}

.logo-img {
  margin-top: 15px;
  width: 110px;
  margin-left: 30px;
}

.ava-img {
  margin-top: 15px;
  width: 50px;
}

.sidebar-list-a {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  vertical-align: text-top;
  margin-left: 10px;
}

.sidebar-list-icon {
  display: inline-block;
}

.sidebar-icon {
  font-size: 19px !important;
  color: rgb(255, 255, 255) !important;
}

.header-icon {
  font-size: 25px !important;
  color: rgb(75, 75, 75) !important;
  margin-left: 20px;
  margin-top: 13px;
}

.sidebar-list-d {
  padding: 7px;
  display: block;
}

.sidebar-list-d:hover {
  background-color: #2196f3;
  border-radius: 5px;
  margin-right: 20px;
  padding: 7px;
  color: black;
  width: -webkit-fill-available;
  display: block;
}

.sidebar-blind-text {
  margin-left: 7px;
  margin-top: 40px;
  color: #ffffff80;
  font-size: 12px;
}

/* Sidebar - end */

/* Header */
.header-welcome-text {
  font-size: 25px;
  font-weight: 900;
  color: rgb(200, 200, 200);
}

.block-header {
  display: flex;
  justify-content: space-between;
}

.header-info-user {
  display: inline-flex;
  margin-top: 10px;
  margin-right: 20px;
  background-color: #e3e3e3;
  border-radius: 50px;
  padding-left: 7px;
  padding-right: 10px;
}

.ava-img {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  margin-right: 10px;
  margin-top: 5px;
}

.text-vert-center {
  display: block;
}

/* Конец Header */

/* Блок водители */
.drivers-block-btn {
  border: 1px solid #d3e0e8;
  border-radius: 10px;
  padding: 15px;
  height: auto !important;
}

.table-drivers {
  background-color: #ffffff;
}

.table-thead {
  background-color: #03a9f4;
}

.textMax {
  color: #282c34;
}

/* Конец Блока водители */

/* Создание нового водителя */

.block-newdriver-info {
  width: auto;
  border-radius: 10px;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 15px;
}

.block-newdriver-info-1-2-2 {
  width: auto;
  border-radius: 10px;
  margin-right: 15px;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 15px;
}

.info-action-stripe {
  width: -webkit-fill-available;
  /* height: 60px; */
  background-color: #ffffff;
  /* border: 1px solid #d5d5d6; */
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  background: radial-gradient(
    circle at 10% 20%,
    #dce5ef 0%,
    rgb(0 66 255 / 0%) 100%
  );
}

.btn-success {
  font-size: 12px !important;
  background-color: #8bc34a !important;
  color: white !important;
  border: none !important;
  text-transform: none !important;
}

.btn-cancel {
  font-size: 12px !important;
  background-color: #d32f2f !important;
  color: white !important;
  border: none !important;
  text-transform: none !important;
  padding: 5px !important;
  margin-left: 10px !important;
  text-decoration: none !important;
}

.block-newdriver-info-first {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 10%);
}

.block-newdriver-info-two {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 10%);
}

.adddriver-input {
  margin-bottom: 10px;
}

/* Конец Создания нового водителя */

.block-driver-div {
  background-color: #7f7f7f;
  width: 300px;
  height: fit-content;
}

.block-driver-real-date {
  font-size: 13px;
  background-color: #673ab7;
  padding: 5px;
  color: white;
  width: fit-content;
  border-radius: 7px;
  margin-bottom: 5px;
}

.block-driver-real-adress {
  font-size: 13px;
  background-color: #efefef;
  padding: 5px;
  color: rgb(0, 0, 0);
  width: fit-content;
  border-radius: 7px;
  margin-bottom: 5px;
}

.block-driver-real-pricedriver {
  border: 1px solid #000000;
  width: fit-content;
  padding: 4px;
  border-radius: 7px;
  font-weight: 700;
  font-size: 13px;
  margin-top: 5px;
}

/* Создание заказа */

.header-mt {
  font-size: 25px;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-block-t {
  font-size: 12px;
  font-weight: bold;
  margin-top: 0;
  background-color: #ffeb3b;
  color: black;
  padding: 4px;
  width: fit-content;
  border-radius: 5px;
}

.grid-add-order-money-result {
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 10px;
}

.grid-add-order-money-result {
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 10px;
}

.grid-add-order-money-result-column {
  display: grid;
  height: fit-content;
  grid-template-columns: 212px 150px 150px;
  column-gap: 10px;
  margin-bottom: 10px;
}

.block-neworder-date {
  width: auto;
  padding: 15px;
  border-radius: 10px;
  margin-right: 15px;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  background-color: #ffffff;
  margin-bottom: 10px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 10%);
}

.block-neworder-infodriver {
  width: auto;
  padding: 15px;
  border-radius: 10px;
  margin-right: 15px;
  height: fit-content;
  background-color: #ffffff;
  margin-bottom: 10px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 10%);
}

.block-neworder-price {
  width: auto;
  padding: 15px;
  border-radius: 10px;
  margin-right: 15px;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  background-color: #ffffff;
  margin-bottom: 10px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 10%);
}

.header-add-2block {
  width: auto;
  margin-right: 15px;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.block-driver-div {
  width: auto;
  border-radius: 10px;
  background-color: white;
  padding: 10px;
}

.header-add-one-block {
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 14px 4px rgb(0 0 0 / 10%);
}

.header-add-two-block {
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #00000016;
}

.helper-text {
  font-size: 10px;
  background-color: #e6e6e6;
  padding: 5px;
  border-radius: 7px;
}

.b-status-grid {
  display: grid;
  grid-template-columns: 185px 1fr;
  grid-gap: 10px;
  margin-bottom: 5px;
}

.text-form-itogo-calc {
  font-size: 14px;
  font-weight: 300;
  margin: 0px;
}

/* Конец Создание заказа */

table {
  width: -webkit-fill-available;
  border-spacing: 0px;
}

td {
  padding: 5px;
  font-size: 13px;
  border-top: 3px solid #f3f3f3;
}

tr:hover {
  padding: 5px;
  font-size: 12px;
  border-top: 5px solid #c72121;
  background-color: #dbff5c;
}

tr {
  background-color: #ffffff;
  border-bottom: 3px solid #000000;
}

th {
  padding: 7px;
  font-size: 12px;
  text-align: left;
  background-color: #171f22;
  color: white;
}

.th-left {
  border-radius: 20px 0 0 20px;
  padding: 7px;
  font-size: 12px;
  text-align: left;
  background-color: #171f22;
  color: white;
  border: none;
  /* если хотите убрать стандартную границу */
  outline: none;
  /* если хотите убрать фокусное кольцо */
}

.th-right {
  border-radius: 0px 20px 20px 0px;
  padding: 7px;
  font-size: 12px;
  text-align: left;
  background-color: #171f22;
  color: white;
  border: none;
  /* если хотите убрать стандартную границу */
  outline: none;
  /* если хотите убрать фокусное кольцо */
}

#customers tr:hover {
  background-color: #ddd;
}

.shapka {
  background-color: #03a9f4;
}

/* Стили для форм, адд, редактирования */

.blue-btn {
  background-color: royalblue !important;
  color: white !important;
  text-transform: none !important;
  margin-right: 10px !important;
  font-size: 12px !important;
}

.text-around-input-form {
  font-size: 14px;
  font-weight: 900;
}

/* Создание автомобиля */

.box-modal-succes {
  border-radius: 20px !important;
}

.modal-grid-succes {
  margin: 0px !important;
  grid-gap: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-grid-order-view {
  margin: 0px !important;
  grid-gap: 20px;
}

.modal-grid-succes-icon {
  font-size: 35px !important;
  color: #9acd32 !important;
}

.modal-grid-delete-icon {
  font-size: 65px !important;
  color: #f44336 !important;
  margin-bottom: 20px;
}

.modal-grid-succes-text {
  margin: 0;
  padding: 0;
  /* font-weight: bold; */
  font-size: 16px;
}

.h-150 {
  height: 150px;
}

.modal-back-btn {
  text-align: center;
    font-size: 12px;
    margin: 0;
    /* padding: 16px; */
    width: 100;
    display: block;
    /* margin-top: 30px; */
    border-radius: 10px;
    text-decoration: underline;
    color: #0047ff;
    margin-top: 20px;
}

.modal-grid-succes-text-a {
  text-align: center;
  font-size: 14px;
  margin: 0;
  padding: 16px;
  background-color: #9acd31;
  width: 100;
  display: block;
  margin-top: 30px;
  border-radius: 10px;
  color: black;
}

.date-block-5-t-v-t-v-v {
  display: grid;
  grid-template-columns: 212px 1fr 0.6fr 0.6fr;
  grid-gap: 10px;
  margin-bottom: 5px;
}

.modal-grid-close-icon {
  text-align: end;
  font-size: 14px;
  margin: 0;
  padding: 10px;
  background-color: #9acd31;
  width: 20;
  display: block;
  margin-top: 30px;
  border-radius: 10px;
  color: black;
}

.chip-classe {
  display: inline-flex;
  background-color: #e2e2e2;
  padding: 7px;
  border-radius: 20px;
  margin-right: 14px;
}

.chip-classe-add {
  display: inline-flex;
  border: 1px solid #436cff;
  padding: 7px;
  border-radius: 20px;
  margin-right: 14px;
}

.chip-classe-add:hover {
  display: inline-flex;
  border: 1px solid #436cff;
  color: white;
  padding: 7px;
  border-radius: 20px;
  margin-right: 14px;
  box-shadow: 0px 0px 15px 3px #1189ff40;
}

.chip-text {
  margin: 0px;
  margin-left: 10px;
  margin-right: 10px;
}

.chip-text-add {
  margin: 0px;
  margin-left: 10px;
  margin-right: 10px;
  color: #436cff;
}

.chip-icon {
  color: #8d8d8d !important;
}

.chip-icon:hover {
  color: #ff3030 !important;
}

.icon-table {
  font-size: 20px !important;
  background-color: #dce5ef;
  padding: 5px;
  border-radius: 5px;
  color: #535960;
  margin-right: 10px;
}

.icon-table:hover {
  font-size: 20px !important;
  background-color: #0a7eff;
  padding: 5px;
  border-radius: 5px;
  color: #ffffff;
  margin-right: 10px;
}

.btn-icon-table {
  padding: 0px !important;
  margin: 0px !important;
  min-width: 15px !important;
}

.td-table-end-action {
  width: 80px !important;
}

.td-table-4btn-action {
  width: 160px;
}

.breadcrumbs-first {
  color: black !important;
  font-weight: 100 !important;
}

/* Таблица - Список заказов */
.table-point-adress-icon-green {
  font-size: 9px !important;
  color: #4caf50 !important;
  margin-right: 5px !important;
}

.table-point-adress-icon-black {
  font-size: 9px !important;
  color: rgb(0, 0, 0) !important;
  margin-right: 5px !important;
}

.chip-order-table-status {
  margin: 0px;
  padding: 2px auto 2px auto;
  font-size: 12px;
}

.chip-new_order {
  background-color: #fed709;
  margin: 0px;
  padding: 5px 5px 5px 5px;
  text-align: center;
  border-radius: 5px;
  width: -webkit-fill-available;
  font-size: 12px;
}

.chip-tires-seasons {
  margin: 0px;
  text-align: center;
  border-radius: 5px;
  width: -webkit-fill-available;
  font-size: 24px;
}

.p-chip-tires-seasons {
  margin: 0px;
}

.chip-succes_order {
  background-color: #32d74b;
  margin: 0px;
  padding: 5px 5px 5px 5px;
  text-align: center;
  border-radius: 5px;
  width: -webkit-fill-available;
  font-size: 12px;
}

.chip-wait_order {
  background-color: #2094fb;
  margin: 0px;
  padding: 5px 5px 5px 5px;
  text-align: center;
  border-radius: 5px;
  width: -webkit-fill-available;
  font-size: 12px;
}

.chip-dtp_order {
  background-color: #f63329;
  margin: 0px;
  padding: 5px 5px 5px 5px;
  text-align: center;
  border-radius: 5px;
  width: -webkit-fill-available;
  font-size: 12px;
}

/*
.chip-order-table-status {
  background-color: #FFE082;
  margin: 0px;
  padding: 2px 5px 2px 5px;
  text-align: center;
  border-radius: 20px;
  width: -webkit-fill-available;
  font-size: 10px;
*/

.chip-order-table-classe {
  /* border: 1px solid #000000; */
  color: #9f9f9f;
  margin: 3px 0px 0px 0px;
  padding: 2px 5px 2px 5px;
  text-align: center;
  border-radius: 20px;
  width: -webkit-fill-available;
  font-size: 10px;
  border: 1px solid #9f9f9f;
}

.table-date-text-black {
  color: rgb(0, 0, 0);
  font-size: 13px;
}

.table-date-text-gray {
  color: rgb(159, 159, 159);
  font-size: 12px;
}

.table-partner-text-black {
  color: rgb(0, 0, 0);
}

.table-class_auto {
  color: rgb(255 255 255);
  background-color: #00000099;
  padding: 2px 5px 2px 5px;
  width: fit-content;
  border-radius: 5px;
}

.table-partner-text-gray {
  color: rgb(159, 159, 159);
}

/* Таблица конец - Список заказов */

/* Кнопки */

.btn-add-to-table {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: fit-content !important;
}

.grid-2column {
  display: grid;
  grid-template-columns: 400px 400px;
  gap: 50px;
}

.m-top-0 {
  margin-top: 0px !important;
}

.btn-add-to-table:hover {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #002ac2 !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transform: scale(1.07) !important;
  box-shadow: 1px 4px 37px 4px rgba(44, 139, 255, 0.81);
}

.btn-add-to-table:active {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transform: scale(0.9) !important;
}

.btn-settings-table {
  padding: 7px 15px 7px 15px;
  color: white !important;
  background-color: #dce5ef !important;
  text-transform: capitalize !important;
  width: 30px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: fit-content !important;
  min-width: fit-content !important;
  line-height: 1 !important;
  padding: 6.6px !important;
  border: none !important;
}

.btn-settings-table:hover {
  padding: 7px 15px 7px 15px;
  color: white !important;
  background-color: #dce5ef !important;
  text-transform: capitalize !important;
  width: 30px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: fit-content !important;
  min-width: fit-content !important;
  line-height: 1 !important;
  padding: 6.6px !important;
  border: none !important;

  transform: scale(1.09) !important;
  box-shadow: 1px 4px 37px 4px rgba(44, 139, 255, 0.81);
}

.btn-update-repair {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #ff9800 !important;
  text-transform: capitalize !important;
  width: -webkit-fill-available !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: fit-content !important;
  border: none !important;
  text-decoration: none !important;
}

.btn-update-repair:hover {
  background-color: #ff7900 !important;
  transform: scale(1.07) !important;
  box-shadow: 1px 4px 37px 4px rgba(255 152 0);
}

.btn-update-repair:active {
  border-radius: 10px !important;
  transform: scale(0.9) !important;
}

.icon-setting {
  width: 18px !important;
  color: #a8adb5 !important;
}

.btn-save-to-table {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #9acd31 !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  border: none !important;
}

.btn-save-to-table:hover {
  padding: 7px 15px 7px 15px !important;
  color: rgb(255, 255, 255) !important;
  background-color: #52d921 !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transform: scale(1.07) !important;
  box-shadow: 1px 4px 37px 4px rgba(60, 255, 0, 0.81);
  border: none !important;
}

.btn-save-to-table:active {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #9acd31 !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transform: scale(0.9) !important;
  border: none !important;
}

.btn-back-to-table {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #f44336 !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  border: none !important;
}

.btn-back-to-table:hover {
  padding: 7px 15px 7px 15px !important;
  color: rgb(255, 255, 255) !important;
  background-color: #f0665c !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transform: scale(1.07) !important;
  box-shadow: 1px 4px 37px 4px rgba(255, 0, 0, 0.81);
  border: none !important;
}

.btn-back-to-table:active {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #f44336 !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transform: scale(0.9) !important;
  border: none !important;
}

.btn-free-to-table {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  border: none !important;
}

.btn-free-to-table:hover {
  padding: 7px 15px 7px 15px !important;
  color: rgb(255, 255, 255) !important;
  background-color: #213ea5 !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transform: scale(1.07) !important;
  box-shadow: 1px 4px 37px 4px #0038ff;
  border: none !important;
}

.btn-free-to-table:active {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transform: scale(0.9) !important;
  border: none !important;
}

.info-active-block-table {
  width: auto;
  border-radius: 10px;
  display: grid;
  height: fit-content;
  grid-template-columns: auto auto 1fr auto;
  grid-gap: 5px;
  margin-bottom: 10px;
}

.info-active-deposit {
  width: auto;
  border-radius: 10px;
  display: grid;
  height: fit-content;
  grid-template-columns: 125px auto;
  grid-gap: 5px;
  margin-bottom: 10px;
}

.info-active-block-table-view-repair {
  width: auto;
  border-radius: 10px;
  display: grid;
  height: fit-content;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
  margin-bottom: 10px;
}

.search-input-auto {
  border: 1px solid #d3e0e8;
  border-radius: 10px;
  padding: 10px;
  height: auto !important;
}

.info-active-block-table-4btn {
  width: auto;
  border-radius: 10px;
  display: grid;
  height: fit-content;
  grid-template-columns: auto auto auto auto 1fr;
  grid-gap: 5px;
  margin-bottom: 10px;
}

.info-active-icon-settings {
  color: #a8adb5;
  background-color: #dce5ef;
  border-radius: 10px;
  padding: 0px 7px 0px 7px;
  height: -webkit-fill-available !important;
  transition: 2s;
  height: fit-content !important;
}

.h-info-settings {
  height: -webkit-fill-available !important;
}

.info-active-icon-settings:hover {
  color: #000000;
  background-color: #dce5ef;
  border-radius: 10px;
  padding: 0px 7px 0px 7px;
  height: -webkit-fill-available !important;
  rotate: 90deg;
  transition: 0.5s;
  box-shadow: 1px 4px 37px 4px rgba(44, 139, 255, 0.81);
  transform: scale(1.07) !important;
  height: -webkit-fill-available !important;
}

.info-active-icon-settings:active {
  color: #a8adb5;
  background-color: #dce5ef;
  border-radius: 10px;
  padding: 0px 7px 0px 7px;
  height: -webkit-fill-available !important;
  transition: 0.2s;
  transform: scale(0.8) !important;
}

/* Модальное окно */

.icon-modal-add-1-input {
  padding: 8px;
  border-radius: 10px;
  background-color: rgb(0 0 0 / 12%);
  margin-bottom: 15px;
}

.icon-modal-add-2-input {
  padding: 8px;
  border-radius: 10px;
  background-color: #9acd31;
  margin-bottom: 15px;
  color: black;
}

.icon-modal-add-2-input:hover {
  padding: 8px;
  border-radius: 10px;
  background-color: #9fdd20;
  margin-bottom: 15px;
  color: black;
  transform: scale(1.3) !important;
  box-shadow: 1px 4px 37px 4px rgb(95 214 25);
  transition: 0.4s;
}

.icon-modal-add-2-input:active {
  padding: 8px;
  border-radius: 10px;
  background-color: #9fdd20;
  margin-bottom: 15px;
  color: black;
  transform: scale(0.8) !important;
  box-shadow: 1px 4px 37px 4px rgb(95 214 25);
  transition: 0.4s;
}

.box-modal-grid-2-icon-input {
  width: auto;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 10px;
  margin-top: 15px;
}

.btn-modal-add-group {
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
  margin-left: 0px !important;
  min-width: 0px !important;
}

.css-19kzrtu {
  padding: 0px !important;
  margin-top: 20px !important;
}

.chip-task {
  background-color: #f44336;
  padding: 4px;
  border-radius: 6px;
  margin-left: 5px;
}

.input-damage {
  padding: 7px;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  width: 255px;
  height: 25px;
  /* font-family: 'Rubik'; */
  /* font-weight: bold; */
  font-size: 18px;
}

/* Страница повреждений */
.block-mg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.label-input-add-damage {
  margin: 0px;
  margin-bottom: 5px;
  font-size: 12px;
  color: gray;
}

.input-add-damage {
  padding: 7px;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  width: -webkit-fill-available;
  height: 25px;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.btn-add-money {
  width: -webkit-fill-available;
  margin-top: 10px !important;
  border-radius: 10px !important;
  height: 50px;
  color: #000000 !important;
  border: none !important;
  background-color: #8bc34a !important;
}

.select-bank-sber {
  background-color: #09a752 !important;
  color: white !important;
}

.select-bank-tink {
  background-color: #fcdb1f !important;
  color: rgb(0, 0, 0) !important;
}

.select-bank-alfa {
  background-color: #f02727 !important;
  color: white !important;
}

.btn-transactions-delete {
  width: 15px !important;
  height: 15px !important;
  color: white !important;
  margin: 0px;
  background-color: red;
  padding: 5px;
  border-radius: 10px;
}

.rubles-simbol {
  font-size: 13px;
  font-weight: 900;
}

.end-actions-table {
  text-align: end;
}

.center-table {
  text-align: center;
}

.comment-table {
  width: 400px;
}

.green-highlight {
  background-color: #90ee90;
  /* Светло-зеленый фон */
}

.grid-2col {
  display: flex;
  justify-content: space-between;
}

.btn-add-damage-paint {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: fit-content !important;
  border: none;
  height: 40px !important;
}

.btn-add-damage-paint:hover {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: fit-content !important;
  border: none;
  height: 40px !important;
  transform: scale(1.07) !important;
  box-shadow: 1px 4px 37px 4px rgba(44, 139, 255, 0.81);
}

.btn-add-damage-paint:active {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: fit-content !important;
  border: none;
  height: 40px !important;
  transform: scale(0.9) !important;
}

.photo-box {
  border: 1px dashed gray;
  padding: 10px;
  margin: 10px;
  text-align: center;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-photo-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.photos-grid {
  display: flex;
  flex-wrap: wrap;
}

.preview-img {
  width: 100%;
  height: auto;
}

.photo {
  height: 100%;
}

.photo-item {
  height: 100px;
  width: 100px;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 5px;
  margin-top: 5px;
}

.green_input {
  border: none;
  background-color: #dcedc8;
}

.select_deposit {
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  width: -webkit-fill-available;
  /* height: 25px; */
  /* font-size: 16px; */
  /* font-weight: 600; */
  font-family: Arial, Helvetica, sans-serif;
}

.editable {
  background-color: #ebebeb;
}

.btn-add-deposit {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 120px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.btn-update-deposit {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #9e9e9e !important;
  text-transform: capitalize !important;
  width: 120px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  border: none !important;
}

.btn-save-deposit {
  padding: 7px 15px 7px 15px !important;
  color: white !important;
  background-color: #9acd31 !important;
  text-transform: capitalize !important;
  width: 120px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  border: none !important;
}

.f-roboto-400 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.f-roboto-500 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 13px;
}

.f-roboto-500b {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 13px;
}

.f-roboto-700 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.f-roboto-900 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 25px;
}

.iconZ {
  width: 17px;
}

.b-icon {
  display: "flex";
}

.mb-5px {
  margin-bottom: 5px;
  color: gray;
}

.block-grid-deposit-view {
  display: grid;
}

.deposit-text {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}

.input-deposit-none {
  padding: 7px;
  border: none;
  text-align: end;
  border-radius: 6px;
  width: 255px;
  height: 25px;
  font-size: 16px;
  font-weight: 400;
}

.box-deposit_grid-2 {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 20px;
}

.box-white {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  height: fit-content;
}

.b-none {
  position: none !important;
}

.info-active-deposit-panel {
  width: auto;
  border-radius: 10px;
  display: grid;
  height: fit-content;
  grid-template-columns: 125px 125px auto;
  grid-gap: 5px;
  margin-bottom: 10px;
}
.mr-15 {
  margin-right: 15px !important;
}
.delete-icon-deposit {
  font-size: 20px !important;
  background-color: #ff855e;
  padding: 5px;
  border-radius: 5px;
  color: #ffffff;
  margin-right: 10px;
}

.text-a-end {
  text-align: end;
}

.box-damage-container {
  display: flex !important;
  gap: 20px;
}

.box-damage-block {
  height: 50px;
  width: 210px;
  background-color: #ffffff;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.box-damage-block:hover {
  transition: 0.3s;
  transform: scale(1.07) !important;
}

.box-damage-block.active {
  transition: 0.2s;
  transform: scale(1) !important;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
}
.t-header-filter {
  margin: 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 15px;
}

.t-mini-filter {
  margin: 0px;
  margin-top: 5px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 13px;
}

.filter-icon-box {
  text-align: center;
  background-color: #7bbaff;
  border-radius: 15px;
  padding: 10px;
  margin-right: 10px;
  font-size: 35px !important;
  color: white;
}

.flex-center {
  display: flex !important;
  align-items: center;
}

.gap-30 {
  gap: 30px !important;
}

.fonts-table-style {
  font-family: "Tektur", sans-serif;
  font-size: 255px;
}

.cheap-lising {
  margin: 0px;
  font-size: 12px;
  color: #ff0000;
  align-items: center;
  text-align: center;
}

.gos {
  margin: 0px;
  border: 1px solid #d5d5d5;
  padding: 4px;
  border-radius: 7px;
  text-align: center;
  font-weight: 600;
  width: 90px;
}

/* Update auto */
.update-flex-grid {
  display: flex;
  align-items: center;
}

.t-for-update {
  font-size: 13px;
  font-weight: 300;
  width: 200px;
}

.t-center {
  text-align: center;
}

.block-box-news {
  background-color: white;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 5px;
}

.p-block-news {
  margin: 0px;
  font-size: 14px;
}

.grid-news {
  display: grid;
}

.auto-info{
  background-color: white;
  width: fit-content;
  padding: 10px;
  border-radius: 15px;
}

.auto-block-flex {
  display: flex;
  gap: 15px;
}

.mini-auto-info-text {
  font-size: 13px;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 3px;
}
.micro-auto-info-text {
  font-size: 11px;
  font-weight: 200;
  margin: 0px;
}

.gos-auto-info{
  margin: 5px 0px 5px 0px;
    font-weight: 900;
    font-size: 14px;
}

.badge-date-text {
  background-color: #e4e4e4;
  width: fit-content;
  padding: 3px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 3px;
}

.past-to-date {
  background-color: yellow;
}

/* хранилище */
/* Стили для облачного хранилища */
.storage-breadcrumbs {
  padding: 16px 0;
  color: #666;
}

.breadcrumb-btn {
  background: none;
  border: none;
  color: #1976d2;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.breadcrumb-btn:hover {
  background-color: rgba(25, 118, 210, 0.04);
}

.storage-toolbar {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}

.toolbar-btn {
  text-transform: none !important;
}

.storage-content {
  background: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.folders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.folder-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: #f5f5f5;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.folder-item:hover {
  background: #eeeeee;
}

.folder-content {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.folder-icon {
  color: #FFC107;
  font-size: 24px !important;
}

.folder-name {
  font-size: 14px;
  color: #333;
}

.files-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: #f5f5f5;
  border-radius: 8px;
}

.file-link {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: inherit;
  flex: 1;
}

.file-icon {
  color: #2196F3;
  font-size: 24px !important;
}

.file-name {
  font-size: 14px;
  color: #333;
}

.delete-icon {
  color: #666;
  cursor: pointer;
  font-size: 20px !important;
  opacity: 0.5;
  transition: all 0.2s;
}

.delete-icon:hover {
  opacity: 1;
  color: #f44336;
}

/* Анимации */
.folder-item, .file-item {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}